<template>
  <PageHeaderLayout>
    <div class="main-page-content">
      <ApeMenu :menus="menus" />
    </div>
  </PageHeaderLayout>
</template>


<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'

export default {
  name: 'RevenueMenu',
  components: {
    PageHeaderLayout,
  },
  data() {
    return {
      menus: [
        { icon: 'bangdan', display_name: '总营收一览', url: '/revenue_menu/revenue_statistics', permission_name: 'revenue_data__revenue' },
        { icon: 'bangdan', display_name: '佣金收入', url: '/revenue_menu/commission_list', permission_name: 'revenue_data__commission' },
        { icon: 'yingshou', display_name: '实时订单排行', url: '/revenue_menu/order_statistics', permission_name: 'order_statistics' },
        { icon: 'fenxi', display_name: '孵化分析', url: '/revenue_menu/growth_statistics', permission_name: 'growth_manager' },
        // { icon: 'icon-yingshou', display_name: '孵化分析', url: '/work_manage', permission_name: 'work_manage_list' },
      ],
    }
  },
}
</script>

<style scoped></style>
